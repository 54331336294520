import {FC, PropsWithChildren} from 'react';

import {Layout} from '~/modules';
import {Outlet} from '@remix-run/react';

export const BaseLayout: FC<PropsWithChildren<Record<never, unknown>>> = ({
  children,
}) => {
  return <Layout>{children || <Outlet />}</Layout>;
};
